
import {Component, Ref, Vue} from 'vue-property-decorator'
import AjandaInfo from "@/components/infos/AjandaInfo.vue";
import IsList from "@/components/lists/IsList.vue";
import IsSablonList from "@/components/lists/IsSablonList.vue";

@Component({
  components: {IsSablonList, IsList, AjandaInfo}
})
export default class AjandaView extends Vue {
  @Ref('isList') readonly isList!: IsList;
  @Ref('ajanda') readonly ajanda!: AjandaInfo;

  loadSablonPicker() {
    this.isList?.isForm?.loadSablon();
  }

  loadAjanda() {
    this.ajanda?.load();
  }
}
