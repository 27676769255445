

import {Component, Mixins, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import EtkinlikForm from "@/components/forms/EtkinlikForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {EtkinlikEntity} from "@/entity/EtkinlikEntity";
import {VCalendar} from "@/types";
import {KisiEntity} from "@/entity/KisiEntity";

@Component({
  components: {DeleteDialog, EtkinlikForm, FormDialog}
})
export default class AjandaInfo extends Mixins(ObjectInputMixin) {

  searchText = ''
  takvimTipi = "month";
  focus:Date|string= '';
  today = '';
  title = '';
  takvimTipLabel = {
    month: 'Aylık',
    week: 'Haftalık',
    day: 'Günlük',
    '4day': '4 Günlük',
  }
  selectedEvent: EtkinlikEntity = {} as EtkinlikEntity;
  selectedElement: EventTarget | null = null;
  selectedOpen = false;

  start: Start | null = null;
  end: End | null = null;
  events: Event[] = [];

  etkinlikler:Array<EtkinlikEntity> = [];


  setSelectEvent(event:EtkinlikEntity){
    this.takvimTipi='day'
    this.focus=event.start_date
  }

  mounted() {
    this.today = new Date().toISOString();
    this.$nextTick(() => {
      if (this.$refs.calendar) {
        (this.$refs.calendar as VCalendar).checkChange();
      }
    });
    this.load();
  }

  showEvent({ nativeEvent, event }: { nativeEvent: MouseEvent, event: EtkinlikEntity }) {
    const open = () => {
      this.selectedEvent = event;
      this.selectedElement = nativeEvent.target;
      requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true));
    }
    if (this.selectedOpen) {
      this.selectedOpen = false;
      requestAnimationFrame(() => requestAnimationFrame(() => open()));
    } else {
      open();
    }
    nativeEvent.stopPropagation();
  }

  updateRange({start, end}: { start: Start; end: End }) {
    this.start = start;
    this.end = end;
    this.title = new Date(start.date).toLocaleString("tr-TR", {month: 'long'}) + " " + start.year;
  }

  viewDay({date}: { date: string }) {
    this.focus = date;
    this.takvimTipi = 'day';
  }

  setToday() {
    this.focus = this.today;
  }

  prev() {
    if (this.$refs.calendar) {
      (this.$refs.calendar as VCalendar).prev();
    }
  }

  next() {
    if (this.$refs.calendar) {
      (this.$refs.calendar as VCalendar).next();
    }
  }

  load() {
    this.$http.get('/api/v1/personel/' + this.$store.state.user.id + '/etkinlik').then((response) => (this.etkinlikler = response.data));
  }

  deleteEtkinlik(etkinlik: EtkinlikEntity) {
    if (etkinlik.hatirlatici_id)
      this.$http.delete('/api/v1/hatirlatici/' + etkinlik.hatirlatici_id);
    if (etkinlik.on_hatirlatici_id)
      this.$http.delete('/api/v1/hatirlatici/' + etkinlik.on_hatirlatici_id);
    this.load();
  }
}
export type Start = {
  date: string;
  time: string;
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  weekday: number;
  hasDay: boolean;
  hasTime: boolean;
  past: boolean;
  present: boolean;
  future: boolean;
}
export type End = {
  date: string;
  time: string;
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  weekday: number;
  hasDay: boolean;
  hasTime: boolean;
  past: boolean;
  present: boolean;
  future: boolean;
}
export type Event = {
  name: string;
  start: string;
  end: string;
  color: string;
  details: string;
}
