

import {Component, Mixins} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {IsSablonEntity} from "@/entity/IsSablonEntity";
import FormWrapper from "@/components/FormWrapper.vue";

@Component({
  components:{FormWrapper}
})
export default class IsSablonForm extends Mixins(SimpleInputMixin) {
  localValue!: IsSablonEntity;
}
