

import {Component, Vue} from "vue-property-decorator";
import {IsSablonEntity} from "@/entity/IsSablonEntity";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import IsSablonForm from "@/components/forms/IsSablonForm.vue";

@Component({
  components: {IsSablonForm, DeleteDialog, FormDialog}
})
export default class IsSablonList extends Vue {
  items: Array<IsSablonEntity> = [];

  headers = [
    {text: "İş Adı", value: "isim"},
    {text: "İş Açıklaması", value: "aciklama", sortable: false},
    {text: "İşlemler", value: "actions", sortable: false, width: "100", align: 'center'}
  ];

  mounted() {
    this.load();
  }

  load() {
    this.$http.get("/api/v1/is-sablon").then((response: any) => {
      this.items = response.data;
      this.$emit('load');
    });
  }
}
